.grid {
    display: grid;
    gap: 25px;
    margin: auto;
}

/* Grid */
.grid.grid__5colum {
    grid-template-columns: repeat(5, 1fr);
}

.grid.grid__4colum {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid.grid__4colum__footer {
    grid-template-columns: 2fr 2fr 1fr 1fr 2fr;
    gap: 40px;
}

.grid.grid__3colum {
    grid-template-columns: 1fr 1fr 1fr;
}

.grid.grid__2colum {
    grid-template-columns: 1fr 1fr;
}

/* Grid Colum */
.colum.colum100 {
    width: 120%;
}

.colum.colum__center {
    text-align: center;
}

/* Grid Item */
.item.align__center {
    justify-self: auto;
    align-self: center;
}

.item.text__right {
    text-align: right;
}

@media screen and (max-width: 780px) {
    .grid.grid__4colum {
        grid-template-columns: 1fr 1fr;
        gap: 0 50px;
    }

    .grid.grid__3colum {
        grid-template-columns: 1fr;
    }

    .grid.grid__4colum__footer {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .colum.colum__hidden {
        display: none;
    }

    .colum.colum__footer {
        display: flex;
        flex-direction: column;
    }

    .grid.grid__5colum {
        grid-template-columns: 1fr;
    }
}

@media screen and (min-width: 780px) and (max-width: 1100px) {
    .grid.grid__4colum {
        grid-template-columns: 1fr 1fr;
    }

    .grid.grid__3colum {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .grid.grid__2colum {
        grid-template-columns: 1fr 1fr;
    }

    .grid.grid__4colum__footer {
        grid-template-columns: 2fr 1fr 1fr 2fr;
    }

    .colum.colum__hidden {
        display: none;
    }
}