footer {
	max-width: var(--max-width);
	padding: 40px 0px;
}


h1,
h2,
h3,
h5 {
	font-family: Pier Sans;
	font-weight: 700;
	text-transform: uppercase;
}

h4,
h6,
p {
	font-family: Pier Sans;
	font-weight: 200;
}

a {
	font-family: Pier Sans;
	font-weight: 200;
	color: var(--lv1-blue);
	text-decoration: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

a.a__navbar {
	font-weight: 700;
	color: var(--darkcolor);
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 20px;
	font-size: 16px;
}

a.a__footer {
	color: var(--darkcolor);
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 20px;
	font-size: 12px;
}

h1 {
	font-size: 40px;
	letter-spacing: 4px;
}

h4 {
	font-size: 17px;
	line-height: 25px;
	letter-spacing: 0.5px;
}

h5 {
	font-size: 16px;
	letter-spacing: 1px;
}

h6 {
	font-size: 12px;
	letter-spacing: 1.2px;
	line-height: 15px;
}

.special__text {
	font-size: 20px;
	letter-spacing: 1.2px;
	line-height: 20px;
	font-family: Pier Sans;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	font-size: 14px;
	letter-spacing: 1px;
	line-height: 20px;
}

.bluetext {
	color: var(--lv1-blue);
}

.bluetext2 {
	color: var(--lv2-blue);
}

.bluetext3 {
	color: var(--lv3-blue);
}

.whitetext {
	color: white;
}

.blacktext {
	color: black;
}

.greentext {
	color: var(--lv1-green);
}

@media screen and (max-width: 780px) {
	br {
		display: none;
	}

	footer {
		width: 90%;
	}

	.footer__logo {
		width: 20%;
	}
}

@media screen and (min-width: 780px) and (max-width: 1100px) {
	footer {
		width: 90%;
	}

}