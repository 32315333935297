.sidebyside {
    display: flex;
    gap: 15px;
}

.centerself {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
}

.aling__selfcenter {
    align-self: center;
}

.uppercase {
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.no__break {
    display: inline;
}

.background__white {
    background: white;
}

.bluebar {
    width: 90%;
    height: 1px;
    border-radius: 5px;
    background-color: var(--lv1-blue);
    align-self: center;
}

.bluebar.right {
    margin-left: auto;
    margin-right: 0;
}

.bluebarfull {
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background-color: var(--lv1-blue);
    align-self: center;
}

.aling__right {
    text-align: right;
}

.aling__left {
    text-align: left;
}

.aling__center {
    text-align: center;
}

.justify__center {
    justify-content: center;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid var(--lv1-blue);
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: var(--lv1-blue);
}

.space__between {
    justify-content: space-between;
    align-items: center;
}

.no__flex {
    display: block;
}

.height {
    height: 40px;
}

.icon__size20 {
    width: 20px;
}

.icon__size30 {
    width: 30px;
}

.position1 {
    position: relative;
    top: 59px;
}

.bold {
    font-weight: 700;
}

.profile__padding {
    padding: 0 40px;
}

.flex__start {
    align-self: flex-start
}

.flex__start {
    align-self: flex-end
}

input::placeholder {
    font-family: Pier Sans;
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--lv1-blue);
}

input.blacktext::placeholder {
    font-family: Pier Sans;
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    text-transform: uppercase;
    color: black;
}

input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    width: 100%;
    font-family: Pier Sans;
    font-weight: 200;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
}

.button.width100 {
    width: 100%;
}

.no-outline:focus {
    outline: none;
}

/* Modal Box */
/* The Modal (background) */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    padding-top: 100px;
    /* Location of the box */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: #7070708a;
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #fefefe;
    border-radius: 5px;
    width: 30%;
    position: relative;
    top: 110px;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.none {
    display: block;
}

.mobile {
    display: none;
}

.blue__square {
    border: 1px solid var(--lv1-blue);
    padding: 20px;
}

@media screen and (max-width: 780px) {

    .sidebyside {
        display: flex;
        flex-flow: row;
        gap: 15px;
    }

    .modal-content {
        width: 80%;
    }

    .none {
        display: none;
    }

    .mobile {
        display: block;
    }

    .padding100 {
        padding: 0;
    }

    .modal-content {
        top: 70px;
    }

    .modaltext {
        font-size: 11px;
    }
}

@media screen and (min-width: 780px) and (max-width: 1100px) {
    .modal-content {
        width: 50%;
    }
}