.navbar {
    background-color: none;
    position: fixed;
    width: 100%;
    z-index: 1;
}

.navbar.menu--is-fixed {
    box-shadow: 0px 15px 5px -10px #e1e1e1;
    background-color: #ffffff !important;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

.navbar__max__width {
    max-width: var(--max-width);
    display: flex;
    justify-content: space-between;
    margin: auto;
    align-items: center;
}

.navbar ul {
    list-style-type: none;
    display: flex;
    gap: 50px;
    align-items: center;
}

/* Menu Mobile */
.menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 50px 0;
    transform: translate(100%, 0);
    transition: transform 0.3s;
    background: linear-gradient(135deg, #ffffff, #ffffff);
    display: flex;
    flex-direction: column;
    z-index: 100;
}

/* #region Elements */
.header-2 {
    display: none;
    background-color: none;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 15px 5px -10px #e1e1e1;
    background-color: #ffffff !important;
}

.navbar-mobile {
    margin: 20px 20px 0 20px;
}

.menu .menu__action {
    cursor: pointer;
    text-transform: uppercase;
    color: #111;
    margin: 0 0 5px 0;
    padding: 10px 20px;
    padding-left: 100px;
    -webkit-tap-highlight-color: transparent;
}

.text__menu {
    color: black;
    text-align: right;
    margin: 0 70px;
}

.menu .text__menu__links {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s;
    background-color: #b1a04f;
    transition: padding 0.3s;
}

.menu .text__menu__link {
    margin-bottom: 25px;
    color: #020202;
    font-size: 14px;
    font-weight: 700;
    padding-left: 50px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: "Open Sans";
}

.menu .text__menu__link.--is-no-margin {
    margin: 0;
}

.menu .text__menu__links--is-open {
    max-height: 1000px;
    background-color: #b1a04f;
    margin-top: 20px;
    padding: 20px 0;
}

.text__menu .text__menu-blue {
    color: #b1a04f;
    margin-bottom: 30px;
}

.text__menu h3 {
    font-size: 14px;
    color: var(--color-white);
    text-transform: uppercase;
    margin-top: 30px;
    padding-left: 50px;
}

.ico__menu {
    display: flex;
    justify-self: right;
    left: 100px;
    margin: 20px;
    justify-content: right;
    position: relative;
}

.menu-icon {
    filter: invert(13%) sepia(74%) saturate(7433%) hue-rotate(239deg) brightness(98%) contrast(94%);
}

.menu .menu__social-menu {
    position: relative;
    left: 100px;
    right: 100px;
    padding: 20px;
    display: grid;
    justify-content: right;
}

.menu .menu__social-menu a {
    margin-bottom: 40px;
}

/* #endregion */

/* #region Modifiers */
.menu.menu--is-open {
    transform: translate(0, 0);
    background-color: var(--color-primary);
    box-shadow: -15px 0px 5px -10px #e1e1e1;
}

.menu .menu__action.menu__action--is-close {
    padding-right: 130px;
    margin: 0 0 20px 0;
}

.container__menu {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    z-index: 2px;
}

.hidden2 {
    display: block;
}

.mobile2 {
    display: none;
}

/* #endregion */
@media(max-width:1100px) {
    .header-2 {
        display: block;
        visibility: visible;
    }

    .navbar {
        visibility: hidden;
        display: none;
    }
}

@media(max-width:465px) {
    .menu .menu__action.menu__action--is-close {
        padding-right: 100px;
    }

    .mobile2 {
        display: block;
    }

    .hidden2 {
        display: none;
    }
}

@media(max-width:400px) {

    .menu .menu__action.menu__action--is-close {
        padding-right: 145px;
    }
}