/* Margin */
.marginbot0 {
    margin-bottom: 0;
}

.marginbot {
    margin-bottom: 50px;
}

.marginbot30 {
    margin-bottom: 40px;
}

.marginbot40 {
    margin-bottom: 40px;
}

.marginbot5 {
    margin-bottom: 5px;
}

.margintop {
    margin-top: 50px;
}

.margintop20 {
    margin-top: 20px;
}

.margintop30 {
    margin-top: 30px;
}

.margintop40 {
    margin-top: 40px;
}

.margintop0 {
    margin-top: 0;
}

/* Padding */
.padding100 {
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 100px;
}

.padding50 {
    padding: 50px;
}

.padding0 {
    padding: 0;
}

.paddingtop20 {
    padding-top: 20px;
}

.paddingtop50 {
    padding-top: 50px
}

.paddingbot50 {
    padding-bottom: 50px
}

