/* Text Box */
.textbox100 {
    width: 20%;
}

.textbox70 {
    width: 70%;
}

.textbox50 {
    width: 50%;
}

.textbox40 {
    width: 40%;
}

@media(max-width: 780px) {
    .textbox70,
    .textbox50,
    .textbox100,
    .textbox40 {
        width: auto;
    }

}