:root {
  --max-width: 1440px;
  /* Colors */
  --lv1-blue: #1B3BF2; 
  --lv2-blue: #076DF2;
  --lv3-blue: #0F88F2;
  --darkcolor: #212940;
  --lightcolor: #F2F2F2;
  --lv1-green: #34E834;
}
