@font-face {
  font-family: "Pier Sans";
  font-style: bold;
  font-weight: 700;
  src: local("Pier Sans"), url("../fonts/PierSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Pier Sans";
  font-style: light;
  font-weight: 200;
  src: local("Pier Sans"), url("../fonts/PierSans-Light.woff") format("woff");
}