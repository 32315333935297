.img {
    margin: 0 auto;
    z-index: 0;
}

/* Image Width */
img.img100 {
    width: 100%
}

img.img90 {
    width: 90%
}

img.img60 {
    width: 60%;
}

img.img50 {
    width: 50%;
}

img.img20 {
    width: 50%;
}