.button {
    border-radius: 100px;
    padding: 0px 40px;
    height: 40px;
    line-height: 0%;
    display: flex;
    align-items: center;
}

.button.mobile-margintop {
    margin-top: 0px;
}

/* Button Type */
.button.button__blue {
    background-color: var(--lv1-blue);
    color: var(--lightcolor);
    height: 45px;
}

.button.button__white {
    background-color: white;
    height: 45px;
    border: 1px solid var(--lv1-blue);
}

.button.button__red {
    background-color: rgb(255, 0, 0);
    color: var(--lightcolor);
    height: 45px;
}

.button.button__redoff {
    background-color: rgb(255, 135, 135);
    color: var(--lightcolor);
    height: 45px;
}

.button.button__off {
    background-color: #8899f8;
    color: var(--lightcolor);
    height: 45px;
}

.button.button__line {
    border: 1px solid var(--lv1-blue);
    color: var(--lv1-blue);
    gap: 20px;
    height: 45px;
}

.button.button__linegreen {
    border: 1px solid green;
    color: var(--lv1-blue);
    gap: 20px;
}

.button.button__linered {
    border: 1px solid red;
    color: red;
    gap: 20px;
}

.button__newsletter {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    height: 50px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 780px) {
    .button {
        justify-content: center;
        padding: 0px 10px;
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
    }

    .button__newsletter {
        justify-content: space-between;
    }

    .button.mobile-margintop {
        margin-top: 20px;
    }
}


@media screen and (min-width: 780px) and (max-width: 1100px) {
    .button {
        justify-content: center;
        padding: 0px 10px;
        max-width: 100%;
    }

    .button__newsletter {
        justify-content: space-between;
    }
}