.banner {
    background-repeat: no-repeat;
    background-size: cover;
}

/* Banner Background */
.banner.banner__home {
    background-image: url(/img/backgrounds/bg-banner.jpg);
    background-position: bottom;

}

.banner.banner__pricing {
    background-image: url(/img/backgrounds/bg-pricing.jpg);
    background-position: top right;
    margin-top: 50px;
}

.banner.banner__geral {
    background-image: url(/img/backgrounds/bg-geral.jpg);
    background-position: bottom;

}

.banner.banner__partners {
    background-image: url(/img/backgrounds/bg-partners.jpg);
    background-position: bottom;
}

.banner.banner__graphic {
    background-image: url(/img/backgrounds/bg-graph.jpg);
    background-position: top;
}

.banner.banner__profile {
    border: 2px solid var(--lv1-blue);
    border-radius: 10px;
    background: #ECF6FF
}


/* Banner Shadow */
.banner.shadow {
    box-shadow: 0px 15px 5px -10px #e1e1e1;
}

.circle__shadow {
    box-shadow: 0px 0px 30px 0px #e1e1e1;
    border-radius: 500px;
}