.grid.gap100 {
    gap: 10%;
}

.grid.gap150 {
    gap: 15px;
}

.grid.gap200 {
    gap: 20px 20%;
}

.grid.gap300 {
    gap: 30px 30%;
}

.nogap {
    gap: 0;
}

.sidebyside.gap200 {
    gap: 20px 20%;
}

.sidebyside.gap300 {
    gap: 20px 30%;
}