/* Container */
.container {
    max-width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding: 0px 100px 0px 100px;
}

.container2 {
    max-width: 1300px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    padding: 0px 100px 0px 100px;
    width: 90%;
}

/* Container Banner Size */
.container__banner {
    height: 100vh;
}

.container__banner75 {
    height: 80vh;
}

.container__banner50 {
    height: 65vh;
}

.container__banner25 {
    height: 40vh;
}

/* Container Type */
.container90 {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.container80 {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.container50 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.container60 {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.container40 {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.container30 {
    display: flex;
    flex-direction: column;
    width: 30%;
}

.container20 {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.container10 {
    display: flex;
    flex-direction: column;
    width: 10%;
}

.container100 {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    margin: auto;
}

/* Container100 Mods */
.container100.container100__alignleft {
    align-items: flex-start;
    text-align: left;
}

.container100.container100__gap {
    gap: 15px;
}

/* Container50 Mods */
.container50.container50__gap {
    gap: 15px;
}

/* Container Margin */
.container.container__margem50 {
    margin-top: 30px;
}

.container.container__margem100 {
    margin-top: 100px;
}

/* Container Align*/
.container.container__aligntop {
    align-items: flex-start;
}

.container2.container__aligntop {
    align-items: flex-start;  
}

.container.container__alignbot {
    align-items: flex-end;
}

.aling__bot {
    align-self: end;
}

.container50.aling__left {
    align-items: end;
}

.container.container__alignleft {
    padding: 0px;
    gap: 20px;
}

.container.justify__start {
    justify-content: start;
}

.container.mobile-gap {
    gap: 100px;
}


@media screen and (max-width: 780px) {

    .container,
    .container2 {
        flex-flow: column;
        padding: 0%;
        width: 90%;
        gap: 0;
    }

    .container50 {
        width: 100%;
    }

    .container100 {
        width: 100%;
    }

    .container50.aling__left {
        align-items: flex-start;
    }

    .container__banner {
        height: 110vh;
        padding-top: 0px;
    }

    .container100.container100__alignleft {
        align-items: center;
    }

    .container.container__invert {
        flex-direction: column-reverse;
    }

    .container.container__margem50 {
        margin-top: 25px;
    }

    .container.container__margem100 {
        margin-top: 50px;
    }

    .container.container_footer_icons {
        flex-direction: row;
    }

    .container.mobile-gap {
        gap: 50px;
    }

}

@media screen and (min-width: 780px) and (max-width: 1100px) {
    .container {
        padding: 0%;
        width: 90%;
    }

    .container2 {
        padding: 0%;
        width: 90%;
    }
}


.container.container__nogap {
    gap: 0px;
    width: 40%;
}